<template>
    <v-list-item
        @click="$emit('set-active-chat')"
        :key="otherUser.email"
    >
        <v-badge
            color="green"
            :value="otherUser.is_online"
            right
            dot
            offset-x="14"
            offset-y="32"
            >
            <v-list-item-avatar class="ml-0">
                <v-img :src="avatar"></v-img>
            </v-list-item-avatar>
        </v-badge>

        <v-list-item-content>
            <v-list-item-title>
                {{otherUser.name}}
            </v-list-item-title>
            <v-list-item-subtitle v-html="otherUser.email"></v-list-item-subtitle>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
    export default {
        props: ['chat'],
        name: "ChatListItem",
        computed: {
            otherUser() {
                let authId = this.$auth.user().id;
                return this.chat.users.find(u => u.id !== authId);
            },
            avatar() {
                return  this.otherUser.avatar_url
            }
        },
        data: function () {
            return {}
        },
    }
</script>

<style scoped>

</style>
