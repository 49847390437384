<template>
    <dashboard-layout>
        <v-row>
            <v-col cols="12" sm="4">
                <v-card :height="isMobile ? 'calc(100vh - 96px)' : 'calc(100vh - 120px)'">
                    <v-toolbar
                        color="cyan"
                        dark flat
                    >
                        <v-toolbar-title>Inbox</v-toolbar-title>
                    </v-toolbar>
                    <template v-if="!chatsLoading">
                        <v-list
                            v-if="chats.length"
                            two-line
                            class="overflow-y-auto"
                            >
                            <v-list-item-group v-model="activeChatIndex">
                                <chat-list-item
                                    v-for="(item) in chats"
                                    :key="`chat`+item.id"
                                    :chat="item"
                                    @set-active-chat="setActiveChat(item)"
                                />
                            </v-list-item-group>
                        </v-list>
                        <div v-else>
                            <v-card-subtitle>
                                To start a chat with an artist, click the chat icon on the card
                            </v-card-subtitle>
                            <v-img src="/assets/img/chat-instruction.jpg"></v-img>
                        </div>
                    </template>
                </v-card>
            </v-col>
            <v-col>
                <v-card
                    :height="isMobile ? 'calc(100vh - 96px)' : 'calc(100vh - 120px)'"
                    class="d-flex flex-column"
                    >
                    <v-card-text
                        v-if="activeChat && activeChat.messages"
                        id="chat-scroll"
                        class="custom-scrollbar fill-height"
                        >
                        <ul>
                            <li :class="getMessageClass(message)"
                                v-for="message in activeChatMessages" :key="message.id">
                                {{ message.text }}
                            </li>
                        </ul>
                    </v-card-text>

                    <v-card-actions class="mt-auto">
                        <v-textarea
                            outlined
                            auto-grow
                            rows="1"
                            v-model="newMessageText"
                            append-icon="mdi-send"
                            @click:append="handleSendChatMessage"
                        />
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </dashboard-layout>
</template>

<script>
import DashboardLayout from "@/layouts/DashboardLayout";
import Chat from "@/models/Chat";
import Message from "@/models/Message";
import ChatListItem from "@/components/ChatListItem";

export default {
    name: "Index",
    components: {DashboardLayout, ChatListItem},
    computed: {
        activeChatMessages() {
            let sorted = this.$_.clone(this.activeChat.messages)
            sorted = sorted.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
            return sorted
        },
        otherUser() {
            let authId = this.user.id;
            return this.activeChat.users.find(u => u.id !== authId);
        },
        isMobile() {
            return this.$vuetify.breakpoint.xs
        },
    },
    data: function () {
        return {
            chats: [],
            activeChat: {message: []},
            newMessageText: '',
            user: this.$auth.user(),
            chatsLoading: true,
            activeChatIndex: -1,
        }
    },
    async mounted() {
        this.chatsLoading = true
        this.chats = await Chat.get()
        this.chatsLoading = false

        if (this.$route.params.id) {
            await this.initActiveChat()
        }

        window.Echo.private('test-channel')
            .listen('ChatMessageCreated', (e) => {
                if (e.message.user_id === this.user.id) {
                    return
                }
                this.activeChat.messages.push(e.message)
                this.$forceUpdate()
            });
    },
    methods: {
        async handleSendChatMessage() {
            let message = new Message({
                chat_id: this.activeChat.id,
                text: this.newMessageText,
            })
            await message.save()
            this.newMessageText = '';
            this.activeChat = await Chat.find(this.activeChat.id)
        },
        async setActiveChat(item) {
            if (parseInt(this.$route.params.id) !== item.id) {
                await this.$router.push({name: 'dashboard.chats', params: {id: item.id}})
            }

            this.activeChat = await Chat.find(item.id)
        },
        getMessageClass(message) {
            if (!message.user_id) {
                return 'service'
            }
            return message.user_id === this.user.id ? 'me' : 'him'
        },
        async initActiveChat() {
            const ac = this.$_.find(this.chats, c => c.id === parseInt(this.$route.params.id));
            this.activeChatIndex = this.$_.findIndex(this.chats, c => c.id === parseInt(this.$route.params.id));

            await (ac ? this.setActiveChat(ac) : this.$router.push({name: 'dashboard.chats', params: {}}))
        }
    },
    watch: {
        'activeChat.messages': function () {
            this.$nextTick(function () {
                let element = document.getElementById('chat-scroll');
                element.scrollTo({
                    top: element.scrollHeight,
                    left: 0,
                    behavior: 'smooth'
                });
            });
        }
    }
}
</script>

<style scoped lang="scss">
#chat-scroll {
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;

        li {
            padding: 20px;
            max-width: 85%;
            border-radius: 10px;
            margin-bottom: 2px;

            @media (min-width: 600px) {
                max-width: 55%;
                border-radius: 30px;
            }
        }
    }


    .him {
        background: #eee;
        margin-right: auto;
    }

    .service {
        margin-right: auto;
        background: #898787;
    }

    .me {
        margin-left: auto;
        background: #0084ff;
        color: #fff;
    }
}
// scrollbar
.custom-scrollbar {
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 7px;
    }

    &::-webkit-scrollbar-track {
        background: #ECF6FF;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background: #BED6EB;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #5b6873;
    }
}


</style>
